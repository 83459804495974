import gql from 'graphql-tag'

export const GET_PARTNERSHIPS = gql`
  query getPartnerships {
    currentUser {
      id
      currentDepartment {
        id
        clientPartnerships {
          id
          agency {
            name
            id
          }
          departmentPositions {
            id
            wage
            adminPositionList {
              id
              name
            }
          }
        }
      }
    }
  }
`
