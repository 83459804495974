import React, { useEffect, useState } from 'react'
import { Grid, Paper } from '@material-ui/core'
import styled from '@emotion/styled'
import { useLazyQuery } from '@apollo/client'
import { Cost } from './Cost'
import { Options } from './Options'
import { Information } from './Information'
import { ShiftTitle } from './ShiftTitle'
import queryDepartments from './query/departments.gql'
import { GET_PARTNERSHIPS } from './query/fetchPartnerships'

const Container = styled(Paper)`
  margin-bottom: 10px;
  padding: 20px;
  row-gap: 5px;
`

export const Shift = ({
  shift,
  hasStaff,
  shiftIndex,
  removeHandler,
  updateHandler,
  partnerships,
  staffDepartments,
  isFacility,
  showTagManagement,
  dynamicStaffWage,
}) => {
  const { department, position, duration, type } = shift
  const [departments, setDepartments] = useState([])
  const [newPartnerships, setNewPartnerships] = useState(isFacility ? [] : partnerships)

  const [getDepartments] = useLazyQuery(queryDepartments, {
    onCompleted: ({ allDepartments }) => {
      setDepartments(allDepartments)
    },
  })

  const [getPartnerships] = useLazyQuery(GET_PARTNERSHIPS, {
    onCompleted: ({ currentUser }) => {
      setNewPartnerships(currentUser.currentDepartment.clientPartnerships)
    },
  })

  useEffect(() => {
    if (isFacility) {
      if (shift.type === 'agency') {
        getDepartments({ variables: { adminPositionListId: shift.position.id, partnerships: true } })
      }
      if (shift.type === 'inHouse') {
        getDepartments({ variables: { adminPositionListId: shift.position.id, partnerships: false } })
      }
    }
  }, [getDepartments, isFacility, shift.position.id, shift.type])

  useEffect(() => {
    if (shift.type === 'agency' && department) {
      getPartnerships({ variables: { departmentId: department } })
    }
  }, [department, getPartnerships, shift.type])

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ShiftTitle shift={shift} removeHandler={removeHandler} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Information shift={shift} />
        </Grid>
        <Grid item xs={12}>
          <Options
            shift={shift}
            hasStaff={hasStaff}
            shiftIndex={shiftIndex}
            handler={updateHandler}
            departments={departments}
            staffDepartments={staffDepartments}
            isFacility={isFacility}
            showTagManagement={showTagManagement}
            dynamicStaffWage={dynamicStaffWage}
          />
        </Grid>
        {type === 'agency' && (
          <Grid item xs={12}>
            <Cost
              shift={shift}
              duration={duration}
              position={position}
              partnerships={newPartnerships}
              handler={updateHandler}
              shiftIndex={shiftIndex}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  )
}
