import React, { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useCookies } from 'react-cookie'
import _ from 'lodash'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { ShiftDialog } from './ShiftDialog'

import GetShiftInformation from './query/getShiftInformation.gql'
import latestIds from './query/latestIds.gql'
import lastAgencyPosition from './query/lastAgencyPosition.gql'
import queryStaffDepartments from './query/staffDepartments.gql'
import mutationCreateAgencyPosition from './mutation/createAgencyPosition.gql'
import mutationCreateInHousePosition from './mutation/createInHousePosition.gql'
import AddTag from './mutation/addTag.gql'
import AddLabel from './mutation/addLabel.gql'
import { GET_PARTNERSHIPS } from './query/fetchPartnerships'

dayjs.extend(utc)
const ShiftModal = ({
  open,
  setOpen,
  quickData,
  setQuickData,
  isAgencyShiftEnabled,
  inHouseStaffEnabled,
  showTagManagement,
}) => {
  const [apls, setAPLs] = useState([])
  const [partnerships, setPartnerships] = useState([])
  const [cookies] = useCookies(['jwt_token'])
  const [lastLocation, setLastLocation] = useState('')
  const [addressLocation, setAddressLocation] = useState(null)
  const [staffDepartments, setStaffDepartments] = useState([])
  const [latestAgencyPositionId, setLatestAgencyPositionId] = useState(null)
  const [agencyPosition, setAgencyPosition] = useState(null)
  const [dynamicStaffWage, setDynamicStaffWage] = useState(false)
  const departmentId = cookies.current_department_id
  const isFacility = cookies.current_department_is_facility === 'true'
  const isClient = useMemo(() => partnerships.length > 0, [partnerships])

  const handleResultPosition = (shifts) => {
    //
    // Resorting to close the modal for now.
    // Error dealing incoming.
    //
    window.dispatchEvent(new Event('onAgencyShiftCreate'))
    window.dispatchEvent(
      new CustomEvent('refetch-shifts', { detail: { shifts } }),
    )
    if (quickData && quickData.user) {
      window.dispatchEvent(
        new CustomEvent('refetch-staff-shifts', {
          detail: { staffId: quickData.user.staff.id },
        }),
      )
    }
    setOpen(false)
  }

  const [getShiftInformation] = useLazyQuery(GetShiftInformation, {
    variables: { departmentId },
    onCompleted: (data) => {
      setAPLs(data.adminPositionLists)
      setLastLocation(_.get(data, 'lastPosition.location', ''))
      setAddressLocation(_.get(data, 'lastPosition.addressPosition', ''))
      setDynamicStaffWage(
        _.get(data, 'department.inhouseOptions.dynamicStaffWage', false),
      )
    },
  })

  const [getPartnerships] = useLazyQuery(GET_PARTNERSHIPS, {
    onCompleted: ({ currentUser }) => {
      setPartnerships(currentUser.currentDepartment.clientPartnerships)
    },
  })

  const [getLatestIds] = useLazyQuery(latestIds, {
    onCompleted: ({ currentUser }) => {
      setLatestAgencyPositionId(
        currentUser.currentDepartment.latestAgencyPositionId,
      )
    },
  })

  useQuery(lastAgencyPosition, {
    variables: { id: latestAgencyPositionId },
    skip: !latestAgencyPositionId,
    onCompleted: ({ position }) => {
      setAgencyPosition(position)
    },
  })

  const [getStaff] = useLazyQuery(queryStaffDepartments, {
    onCompleted: ({ eventWorker }) => {
      setStaffDepartments(eventWorker.departments)
    },
  })

  const [addTag] = useMutation(AddTag)
  const [addLabel] = useMutation(AddLabel)
  const [createAgencyPosition] = useMutation(mutationCreateAgencyPosition)
  const [createInHousePosition] = useMutation(mutationCreateInHousePosition)

  const handleCreatePosition = (data, orderForAll) => {
    const { shifts, actionType } = data
    let countShift = 0
    shifts.map((shift) => {
      const {
        position,
        qty,
        startAt,
        endAt,
        breakDuration,
        breakQty,
        dates,
        agency,
        location,
        city,
        region,
        country,
        notes,
        days,
        endDate,
        recurring,
        unpaid,
        shiftType,
        department,
        type,
        wage,
        contactName,
        contactPhone,
        orderNotes,
        poNumber,
        dynamicStaffWage: staffWage,
        signupLocation,
        tags,
        labels,
      } = shift

      const variables = {
        adminPositionListId: position.id,
        qty,
        startAt,
        endAt,
        breakDuration,
        breakQty,
        dates: dates.map((date) => dayjs(date).startOf('D')
          .utc(true)
          .toJSON()),
        location,
        city,
        wage: Number(wage),
        region,
        country,
        notes,
        published: actionType === 'post',
        days,
        endDate: dayjs(endDate).startOf('D')
          .utc(true)
          .toJSON(),
        recurring,
        startDate: dayjs(dates[0]).startOf('D')
          .utc(true)
          .toJSON(),
        unpaid,
      }

      if (type === 'agency') {
        variables.agencyId = agency
        variables.contactName = orderForAll ? data.contactName : contactName
        variables.contactPhone = orderForAll ? data.contactPhone : contactPhone
        variables.orderNotes = orderForAll ? data.orderNotes : orderNotes
        variables.poNumber = orderForAll ? data.poNumber : poNumber
        variables.signupLocation = orderForAll
          ? data.signupLocation
          : signupLocation
        return createAgencyPosition({
          variables,
        }).then(({ data: res }) => {
          if (res.createAgencyPosition.positions.length > 0) {
            countShift += 1
            if (countShift === shifts.length) {
              handleResultPosition(shifts)
            }
          }
        })
      }
      if (type === 'inHouse') {
        variables.assignOnly = shiftType
        if (isFacility) {
          variables.departmentId = department
        }
        if (quickData && quickData.user) {
          variables.eventWorkerId = quickData.user.staff.id
        }
        if (dynamicStaffWage) {
          variables.dynamicStaffWage = staffWage
        }
        return createInHousePosition({
          variables,
        }).then(({ data: res }) => {
          if (res.createInHousePosition.errors.length === 0) {
            let count = 0
            const positionCount = res.createInHousePosition.positions.length
            const tagsCount = tags ? tags.length * positionCount : 0
            const labelsCount = labels ? labels.length * positionCount : 0
            if (tagsCount > 0) {
              tags.map((tag) => {
                res.createInHousePosition.positions.map((pos) => {
                  count += 1
                  return addTag({
                    variables: {
                      positionId: pos.id,
                      tagId: tag.id,
                    },
                  })
                })
                return count
              })
            }
            if (labelsCount > 0) {
              labels.map((label) => {
                res.createInHousePosition.positions.map((pos) => {
                  count += 1
                  return addLabel({
                    variables: {
                      positionId: pos.id,
                      labelId: label.id,
                    },
                  })
                })
                return count
              })
            }
            if (count === tagsCount + labelsCount) {
              countShift += 1
            }
            if (countShift === shifts.length) {
              handleResultPosition(shifts)
            }
          }
        })
      }
      return createAgencyPosition({
        variables,
      }).then(({ data: res }) => {
        if (res.createAgencyPosition.positions.length > 0) {
          countShift += 1
          if (countShift === shifts.length) {
            handleResultPosition(shifts)
          }
        }
      })
    })
  }

  useEffect(() => {
    getShiftInformation()
    getLatestIds()
    setQuickData(null)
    setLatestAgencyPositionId(null)
    getPartnerships()
  }, [open, setQuickData, getShiftInformation, getLatestIds, getPartnerships])

  useEffect(() => {
    if (quickData && quickData.user) {
      const { id } = quickData.user.staff
      getStaff({ variables: { id } })
    }
  }, [getStaff, quickData])

  const handleClose = () => setOpen(false)
  return (
    <ShiftDialog
      open={open}
      handleClose={handleClose}
      handleCreatePosition={handleCreatePosition}
      positions={apls}
      partnerships={partnerships}
      lastLocation={lastLocation}
      addressLocation={addressLocation}
      quickData={quickData}
      isClient={isClient}
      staffDepartments={staffDepartments}
      isFacility={isFacility}
      latestAgencyData={agencyPosition}
      isAgencyShiftEnabled={isAgencyShiftEnabled}
      inHouseStaffEnabled={inHouseStaffEnabled}
      showTagManagement={showTagManagement}
      dynamicStaffWage={dynamicStaffWage}
    />
  )
}

export default ShiftModal
