import React from 'react'
import { Grid, Button } from '@material-ui/core'
import styled from '@emotion/styled'
import { Label } from './Label'

const StyledButton = styled(Button)`
  margin-right: 10px;
  border-radius: 20px;
  padding: 5px 10px;
  text-transform: capitalize;
`

const Enabled = ({ handler, children }) => (
  <StyledButton variant="outlined" color="secondary" onClick={handler}>
    {children}
  </StyledButton>
)

const Disabled = ({ children }) => (
  <StyledButton variant="outlined" color="secondary" disabled>
    {children}
  </StyledButton>
)

export const TypeDialog = ({ canAgency, canHyre, canInHouse, shiftHandler, isClient, type }) => {
  const inHouseHandler = () => shiftHandler('inHouse')
  const hyreHandler = () => shiftHandler('hyre')
  const agencyHandler = () => shiftHandler('agency')

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Label>Shift Type</Label>
      </Grid>

      {type ? (
        <>
          {type === 'in-house'
            && (canInHouse ? (
              <Grid item xs={3.2}>
                <Enabled handler={inHouseHandler}>In-House</Enabled>
              </Grid>
            ) : (
              <Grid item xs={3.2}>
                <Disabled>In-House</Disabled>
              </Grid>
            ))}
          {type === 'agency'
            && (canAgency ? (
              <Grid item xs={2.7}>
                <Enabled handler={agencyHandler}>Agency</Enabled>
              </Grid>
            ) : (
              <Grid item xs={2.7}>
                <Disabled>Agency</Disabled>
              </Grid>
            ))}
        </>
      ) : (
        <Grid item xs={12}>
          <Grid container>
            {canInHouse ? (
              <Grid item xs={3.2}>
                <Enabled handler={inHouseHandler}>In-House</Enabled>
              </Grid>
            ) : (
              <Grid item xs={3.2}>
                <Disabled>In-House</Disabled>
              </Grid>
            )}
            {isClient && (
              <>
                {canHyre ? (
                  <Grid item xs={2.7}>
                    <Enabled handler={hyreHandler}>Hyre</Enabled>
                  </Grid>
                ) : (
                  <Grid item xs={2.7}>
                    <Disabled>Hyre</Disabled>
                  </Grid>
                )}
                {canAgency ? (
                  <Grid item xs={2.7}>
                    <Enabled handler={agencyHandler}>Agency</Enabled>
                  </Grid>
                ) : (
                  <Grid item xs={2.7}>
                    <Disabled>Agency</Disabled>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
